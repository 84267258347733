import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { AuthProvider } from '../../contexts/auth';
import { ProfessionalLeaguesProvider } from '../../contexts/professional_leagues';
import { PositionsProvider } from '../../contexts/positions';
import { RulesProvider } from '../../contexts/rules';
import { SeasonsProvider } from '../../contexts/seasons';

import AdminRouter from '../Admin/Router';
import Dashboard from '../Dashboard';
import Header from '../Header';
import LeaguesList from '../LeaguesList';
import PrivateRoute from '../PrivateRoute';

import { AppWrapper } from './styles';

function App() {
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: darkMode.value ? 'dark' : 'light',
          type: 'dark',
          primary: {
            main: '#009ffd',
          },
          secondary: {
            main: '#ffa400',
          },
        },
        typography: {
          fontFamily: '"Inter", -apple-system, "Helvetica Neue", Helvetica, Arial',
        },
      }),
    [true],
    // [darkMode.value],
  );
  return (
    <AuthProvider
      // @ts-ignore
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      // @ts-ignore
      client_id={process.env.REACT_APP_AUTH_CLIENT_ID}
      redirect_uri={window.location.origin}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
    >
      <ProfessionalLeaguesProvider>
        <SeasonsProvider>
          <PositionsProvider>
            <RulesProvider>
              <ThemeProvider theme={theme}>
                <Router>
                  <CssBaseline />
                  <Header />
                  <AppWrapper>
                    <Switch>
                      <PrivateRoute exact path="/" component={Dashboard}/>
                      <PrivateRoute path="/fantasy_leagues" component={LeaguesList}/>
                    </Switch>
                    <AdminRouter />
                  </AppWrapper>
                </Router>
              </ThemeProvider>
            </RulesProvider>
          </PositionsProvider>
        </SeasonsProvider>
      </ProfessionalLeaguesProvider>
    </AuthProvider>
  );
}

export default App;
