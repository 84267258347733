import React from 'react';
import useFetch from 'fetch-suspense';

import {
  Button,
} from '@material-ui/core';

import CreateLeagueDialog from '../CreateLeagueDialog';

import {
  Wrapper,
} from './styles';

const Leagues = () => {
  const [creatingLeague, setCreatingLeague] = React.useState(false);

  const handleCreateModalClose = (didCreate) => {
    setCreatingLeague(false);
  };

  const leagues = useFetch('/api/v1/leagues');

  return (
    <Wrapper>
      <CreateLeagueDialog
        open={creatingLeague}
        onClose={handleCreateModalClose}
      />
      <Button color="primary" variant="contained" size="large" fullWidth onClick={() => setCreatingLeague(true)}>+ Create a League</Button>
    </Wrapper>
  )
};

Leagues.propTypes = {};

const LeaguesWrapper = () => {

  return (
    <React.Suspense fallback={'LOADING'}>
      <Leagues />
    </React.Suspense>
  )
}

export default LeaguesWrapper;
