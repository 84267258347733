import React from 'react';
import MaterialTable from 'material-table';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';

import { useAuth } from '../../../contexts/auth';
import { useProfessionalLeagues, refreshProfessionalLeagues, createProfessionalLeague, putProfessionalLeague } from '../../../contexts/professional_leagues';

import ProfessionalLeagueDialog from '../ProfessionalLeagueDialog';
import Link from '../../Link';

import {
  Wrapper,
} from './styles';

const ProfessionalLeagues = () => {
  const [proLeagueModalOpen, setProLeagueModalOpen] = React.useState(false);
  const [proLeagueModalValues, setProLeagueModalValues] = React.useState({});
  const [proLeaguesState, proLeaguesDispatch] = useProfessionalLeagues();
  const { fetchAuth } = useAuth();

  const handleModalClose = async (values, instance) => {
    if (!values) return setProLeagueModalOpen(false);
    try {
      if (proLeagueModalValues && proLeagueModalValues.id) {
        await putProfessionalLeague(proLeaguesDispatch, fetchAuth, values);
      } else {
        await createProfessionalLeague(proLeaguesDispatch, fetchAuth, values);
      }
      setProLeagueModalOpen(false);
      if (instance && typeof instance.reset === 'function') instance.reset();
    } catch (e) {}
  };

  const leagues = Object.values(proLeaguesState.leaguesById);

  React.useEffect(() => {
    if (!leagues.length) refreshProfessionalLeagues(proLeaguesDispatch, fetchAuth);
  }, []);

  if (proLeaguesState.loading) return <CircularProgress />;
  return (
    <Wrapper>
      <MaterialTable
        title="Professional Leagues"
        data={leagues}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Short Name', field: 'short_name' },
          {
            title: 'Seasons',
            render: data => <Link to={`/admin/professional_leagues/${data.id}/seasons`}>Seasons</Link>,
          },
          {
            title: 'Positions',
            render: data => <Link to={`/admin/professional_leagues/${data.id}/positions`}>Positions</Link>,
          },
          {
            title: 'Rules',
            render: data => <Link to={`/admin/professional_leagues/${data.id}/rules`}>Rules</Link>,
          },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Pro League',
            isFreeAction: true,
            onClick: () => {
              setProLeagueModalValues({});
              setProLeagueModalOpen(true);
            },
          },
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (e, rowData) => {
              setProLeagueModalValues(rowData);
              setProLeagueModalOpen(true);
            },
          }
        ]}
        options={{
          paging: false,
          actionsColumnIndex: 6,
        }}
      />
      <ProfessionalLeagueDialog
        open={proLeagueModalOpen}
        onClose={handleModalClose}
        error={proLeaguesState.error}
        cta={proLeagueModalValues.id ? 'Save' : 'Create'}
        contentText={proLeagueModalValues.id ? `Edit ${proLeagueModalValues.name}` : 'Add a Pro League'}
        defaultValues={proLeagueModalValues}
      />
    </Wrapper>
  )
};

ProfessionalLeagues.propTypes = {};

const ProfessionalLeaguesWrapper = () => {

  return (
    <React.Suspense fallback={'LOADING PRO LEAGUES'}>
      <ProfessionalLeagues />
    </React.Suspense>
  )
}

export default ProfessionalLeaguesWrapper;
