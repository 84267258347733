import React from 'react';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table';
import {
  CircularProgress,
} from '@material-ui/core';

import { useAuth } from '../../../contexts/auth';
import { useProfessionalLeagues, refreshProfessionalLeagues } from '../../../contexts/professional_leagues';
import { useSeasons, refreshSeasons, createSeason, putSeason } from '../../../contexts/seasons';

import SeasonDialog from '../SeasonDialog';
import Link from '../../Link';

import {
  Wrapper,
} from './styles';

const Seasons = () => {
  const [seasonModalOpen, setSeasonModalOpen] = React.useState(false);
  const [seasonModalValues, setSeasonModalValues] = React.useState({});
  const [proLeaguesState, proLeaguesDispatch] = useProfessionalLeagues();
  const [seasonsState, seasonsDispatch] = useSeasons();
  const { fetchAuth } = useAuth();
  const { professionalLeagueId } = useParams();

  const handleCreateModalClose = async (values, instance) => {
    console.log(values);
    if (!values) return setSeasonModalOpen(false);
    try {
      if (seasonModalValues && seasonModalValues.id) {
        await putSeason(seasonsDispatch, fetchAuth, professionalLeagueId, values);
      } else {
        await createSeason(seasonsDispatch, fetchAuth, professionalLeagueId, values);
      }
      setSeasonModalOpen(false);
      if (instance && typeof instance.reset === 'function') instance.reset();
    } catch (e) {}
  };

  const leagues = Object.values(proLeaguesState.leaguesById);
  const seasons = Object.values(seasonsState.seasonsByProfessionalLeagueId[professionalLeagueId] || {});

  React.useEffect(() => {
    if (!leagues.length) refreshProfessionalLeagues(proLeaguesDispatch, fetchAuth);
    if (!seasons.length) refreshSeasons(seasonsDispatch, fetchAuth, professionalLeagueId);
  }, []);

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Year', field: 'year' },
  ];

  if (proLeaguesState.loading || seasonsState.loading) return <CircularProgress />;
  return (
    <Wrapper>
      <Link to="/admin/professional_leagues">Back to Professional Leagues</Link>
      <MaterialTable
        title={`${proLeaguesState.leaguesById[professionalLeagueId].name} Seasons`}
        data={seasons}
        columns={columns}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Season',
            isFreeAction: true,
            onClick: () => {
              setSeasonModalValues({});
              setSeasonModalOpen(true);
            },
          },
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (e, rowData) => {
              setSeasonModalValues(rowData);
              setSeasonModalOpen(true);
            },
          }
        ]}
        options={{
          paging: false,
          actionsColumnIndex: columns.length,
        }}
      />
      <SeasonDialog
        open={seasonModalOpen}
        onClose={handleCreateModalClose}
        error={seasonsState.error}
        cta={seasonModalValues.id ? 'Save' : 'Create'}
        contentText={seasonModalValues.id ? `Edit ${seasonModalValues.name}` : 'Add a Season'}
        defaultValues={seasonModalValues}
      />
    </Wrapper>
  )
};

Seasons.propTypes = {};

const SeasonsWrapper = () => {

  return (
    <React.Suspense fallback={'LOADING PRO LEAGUES'}>
      <Seasons />
    </React.Suspense>
  )
}

export default SeasonsWrapper;
