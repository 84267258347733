import React from 'react';

import {
  Grid,
} from '@material-ui/core';

import {
  Skeleton,
} from '@material-ui/lab';

import {
  Block,
  Wrapper,
} from './styles';

const blocks = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

const Dashboard = () => {
  return (
    <Wrapper>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={3}
      >
        {blocks.map(b => (
          <Grid item>
            <Block>
              <Skeleton variant="rect" width="100%" height="20rem" />
            </Block>
          </Grid>
        ))}
      </Grid>
    </Wrapper>
  )
};

Dashboard.propTypes = {};

export default Dashboard;
