import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  min-width: 30rem;
  min-height: 15rem;
  flex: 1 0 auto;
  border-radius: 4px;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  margin-top: 2rem;
`;
