import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  padding: 0 10vw;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  .MuiTabs-flexContainer {
    > *:not(:last-child) {
      border-right: 1px solid #777;
    }
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
