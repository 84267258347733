import React from 'react';
import PropTypes from 'prop-types';
import Joi from '@hapi/joi';

import Checkbox from '../../Checkbox';
import FormDialog from '../../FormDialog';
import TextField from '../../TextField';

const ChildPosition = ({ position }) => {
  return (
    <Checkbox field="positions" label={position.name} isArray value={position} />
  )
}

const AdminPositionDialog = (props) => {
  return (
    <FormDialog
      {...props}
    >
      <TextField
        label="Name"
        field="name"
        placeholder="Quarterback"
        validator={Joi.string().min(1).required()}
      />
      <TextField
        label="Short Name"
        field="short_name"
        placeholder="QB"
        validator={Joi.string().min(1).required()}
      />
      <Checkbox
        label="Offense"
        field="offense"
      />
      <Checkbox
        label="Defense"
        field="defense"
      />
      <Checkbox
        label="Special Teams"
        field="special_teams"
      />
      <Checkbox
        label="Coach"
        field="coach"
      />
      <p>Players with the following positions are also valid for this position:</p>
      {props.positions.filter(p => !p.positions.length && p.id !== props.defaultValues.id).map(p => <ChildPosition key={p.id} position={p} />)}
    </FormDialog>
  );
};

AdminPositionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  positions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  cta: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  error: PropTypes.any,
};

AdminPositionDialog.defaultProps = {
  defaultValues: {},
};

export default AdminPositionDialog;
