// https://auth0.com/docs/quickstart/spa/react/01-login

import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import useFetch from 'fetch-suspense';
import Hoek from '@hapi/hoek';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const AuthContext = React.createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [authClient, setAuth] = useState();
  const [loading, setLoading] = useState(true);

  const getFetchOpts = (opts) => {

    const options = Hoek.applyToDefaults({
      method: 'get',
      headers: {
        authorization: token,
      },
    }, opts);
    if (options.method.toLowerCase() !== 'get') options.headers['content-type'] = 'application/json';
    if (options.body && typeof options.body !== 'string') options.body = JSON.stringify(options.body);
    return options;
  }

  const refreshUserAndToken = async (client = authClient) => {
    const [
      fetchedUser,
      fetchedToken,
    ] = await Promise.all([
      client.getUser(),
      client.getTokenSilently(),
    ]);
    setUser(fetchedUser);
    setToken(fetchedToken);
  }

  useEffect(() => {
    const initAuth = async () => {
      const authFromHook = await createAuth0Client(initOptions);
      setAuth(authFromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await authFromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await authFromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        await refreshUserAndToken(authFromHook);
      }

      setLoading(false);
    };
    initAuth();
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await authClient.handleRedirectCallback();
    await refreshUserAndToken();
    setLoading(false);
    setIsAuthenticated(true);
  };

  const useFetchAuth = (uri, fetchOpts = {}, useFetchOpts = {}) => {
    const ufopts = Hoek.applyToDefaults({
      metadata: true,
    }, useFetchOpts);
    return useFetch(uri, getFetchOpts(fetchOpts), ufopts);
  }

  const fetchAuth = async (uri, fetchOpts = {}) => {
    const response = await fetch(uri, getFetchOpts(fetchOpts));
    if (!response.ok) {
      throw new Error(`Request Error: ${response.statusText}`);
    }
    return response.json();
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        useFetchAuth,
        fetchAuth,
        user,
        token,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => authClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => authClient.loginWithRedirect(...p),
        getTokenSilently: (...p) => authClient.getTokenSilently(...p),
        getTokenWithPopup: (...p) => authClient.getTokenWithPopup(...p),
        logout: (...p) => authClient.logout(...p),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
