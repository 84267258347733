import React from 'react';
import MaterialTable from 'material-table';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Icon,
} from '@material-ui/core';

import { useAuth } from '../../../contexts/auth';
import { useProfessionalLeagues, refreshProfessionalLeagues } from '../../../contexts/professional_leagues';
import { usePositions, refreshPositions, createPosition, putPosition } from '../../../contexts/positions';

import PositionDialog from '../PositionDialog';
import Link from '../../Link';

import {
  Wrapper,
} from './styles';

const PositionTypeCell = (rowData, key) => {
  if (!rowData[key]) return null;
  return <Icon>check</Icon>;
}

const Positions = () => {
  const [positionModalOpen, setPositionModalOpen] = React.useState(false);
  const [positionModalValues, setPositionModalValues] = React.useState({});
  const [proLeaguesState, proLeaguesDispatch] = useProfessionalLeagues();
  const [positionsState, positionsDispatch] = usePositions();
  const { fetchAuth } = useAuth();
  const { professionalLeagueId } = useParams();

  const handleModalClose = async (values, instance) => {
    if (!values) return setPositionModalOpen(false);
    try {
      if (positionModalValues && positionModalValues.id) {
        await putPosition(positionsDispatch, fetchAuth, professionalLeagueId, values);
      } else {
        await createPosition(positionsDispatch, fetchAuth, professionalLeagueId, values);
      }
      setPositionModalOpen(false);
      if (instance && typeof instance.reset === 'function') instance.reset();
    } catch (e) {}
  };

  const leagues = Object.values(proLeaguesState.leaguesById);
  const positions = Object.values(positionsState.positionsByProfessionalLeagueId[professionalLeagueId] || {});

  React.useEffect(() => {
    if (!leagues.length) refreshProfessionalLeagues(proLeaguesDispatch, fetchAuth);
    if (!positions.length) refreshPositions(positionsDispatch, fetchAuth, professionalLeagueId);
  }, []);

  if (proLeaguesState.loading || positionsState.loading) return <CircularProgress />;
  return (
    <Wrapper>
      <Link to="/admin/professional_leagues">Back to Professional Leagues</Link>
      <h1>{proLeaguesState.leaguesById[professionalLeagueId].name}</h1>
      <MaterialTable
        title="Positions"
        data={positions}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Short Name', field: 'short_name' },
          {
            title: 'Offense',
            field: 'offense',
            render: data => PositionTypeCell(data, 'offense'),
          },
          {
            title: 'Defense',
            field: 'defense',
            render: data => PositionTypeCell(data, 'defense'),
          },
          {
            title: 'Special Teams',
            field: 'special_teams',
            render: data => PositionTypeCell(data, 'special_teams'),
          },
          {
            title: 'Coach',
            field: 'coach',
            render: data => PositionTypeCell(data, 'coach'),
          },
        ]}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Position',
            isFreeAction: true,
            onClick: () => {
              setPositionModalValues({});
              setPositionModalOpen(true);
            },
          },
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (e, rowData) => {
              setPositionModalValues(rowData);
              setPositionModalOpen(true);
            },
          }
        ]}
        options={{
          paging: false,
          actionsColumnIndex: 6,
        }}
      />
      <PositionDialog
        open={positionModalOpen}
        cta={positionModalValues.id ? 'Save' : 'Create'}
        contentText={positionModalValues.id ? `Edit ${positionModalValues.name}` : 'Add a position'}
        onClose={handleModalClose}
        error={positionsState.error}
        positions={positions}
        defaultValues={positionModalValues}
      />
    </Wrapper>
  )
};

Positions.propTypes = {};

const PositionsWrapper = () => {

  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Positions />
    </React.Suspense>
  )
}

export default PositionsWrapper;
