import React from 'react';
import MaterialTable from 'material-table';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Icon,
} from '@material-ui/core';

import { useAuth } from '../../../contexts/auth';
import { useProfessionalLeagues, refreshProfessionalLeagues } from '../../../contexts/professional_leagues';
import { useRules, refreshRules, createRule, putRule } from '../../../contexts/rules';

import RuleDialog from '../RuleDialog';
import Link from '../../Link';

import {
  Wrapper,
} from './styles';

const RuleEnabledCell = (rowData, key) => {
  let icon = 'close';
  if (rowData[key]) icon = 'check';
  return <Icon>{icon}</Icon>;
}

const Rules = () => {
  const [ruleModalOpen, setRuleModalOpen] = React.useState(false);
  const [ruleModalValues, setRuleModalValues] = React.useState({});
  const [proLeaguesState, proLeaguesDispatch] = useProfessionalLeagues();
  const [rulesState, rulesDispatch] = useRules();
  const { fetchAuth } = useAuth();
  const { professionalLeagueId } = useParams();

  const handleModalClose = async (values, instance) => {
    if (!values) return setRuleModalOpen(false);
    try {

      if (ruleModalValues && ruleModalValues.id) {
        await putRule(rulesDispatch, fetchAuth, professionalLeagueId, values);
      } else {
        await createRule(rulesDispatch, fetchAuth, professionalLeagueId, values);
      }
      setRuleModalOpen(false);
      if (instance && typeof instance.reset === 'function') instance.reset();
    } catch (e) {}
  };

  const leagues = Object.values(proLeaguesState.leaguesById);
  const rules = Object.values(rulesState.rulesByProfessionalLeagueId[professionalLeagueId] || {});

  React.useEffect(() => {
    if (!leagues.length) refreshProfessionalLeagues(proLeaguesDispatch, fetchAuth);
    if (!rules.length) refreshRules(rulesDispatch, fetchAuth, professionalLeagueId);
  }, []);

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Coach', field: 'coach_enabled', render: d => RuleEnabledCell(d, 'coach_enabled') },
    { title: 'Coach Points', field: 'coach' },
    { title: 'Team', field: 'team_enabled', render: d => RuleEnabledCell(d, 'team_enabled') },
    { title: 'Team Points', field: 'team' },
    { title: 'Player', field: 'player_enabled', render: d => RuleEnabledCell(d, 'player_enabled') },
    { title: 'Player Points', field: 'player' },
    { title: 'Scoring Function', field: 'function_name' },
  ];

  if (proLeaguesState.loading || rulesState.loading) return <CircularProgress />;
  return (
    <Wrapper>
      <Link to="/admin/professional_leagues">Back to Professional Leagues</Link>
      <MaterialTable
        title={`${proLeaguesState.leaguesById[professionalLeagueId].name} Rules`}
        data={rules}
        columns={columns}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Rule',
            isFreeAction: true,
            onClick: () => {
              setRuleModalValues({});
              setRuleModalOpen(true);
            },
          },
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (e, rowData) => {
              setRuleModalValues(rowData);
              setRuleModalOpen(true);
            },
          }
        ]}
        options={{
          paging: false,
          actionsColumnIndex: columns.length,
        }}
      />
      <RuleDialog
        open={ruleModalOpen}
        onClose={handleModalClose}
        error={rulesState.error}
        cta={ruleModalValues.id ? 'Save' : 'Create'}
        contentText={ruleModalValues.id ? `Edit ${ruleModalValues.name}` : 'Add a Rule'}
        defaultValues={ruleModalValues}
      />
    </Wrapper>
  )
};

Rules.propTypes = {};

const RulesWrapper = () => {

  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Rules />
    </React.Suspense>
  )
}

export default RulesWrapper;
