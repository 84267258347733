import React from 'react';
import PropTypes from 'prop-types';
import Joi from '@hapi/joi';

import FormDialog from '../../FormDialog';
import TextField from '../../TextField';

const ProfessionalLeagueDialog = (props) => {

  return (
    <FormDialog
      {...props}
    >
      <TextField
        label="Full League Name"
        field="name"
        placeholder="National Football League"
        validator={Joi.string().min(1).required()}
      />
      <TextField
        label="Short League Name"
        field="short_name"
        placeholder="NFL"
        validator={Joi.string().min(1).required()}
      />
    </FormDialog>
  );
};

ProfessionalLeagueDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  cta: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  error: PropTypes.any,
};

export default ProfessionalLeagueDialog;
