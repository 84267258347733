import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  AppBar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useAuth } from '../../contexts/auth';

import {
  HeaderLeft,
  HeaderRight,
  HeaderWrapper,
} from './styles';

const HeaderTabs = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleTabChange = (e, path) => {
    history.push(path);
  }

  const rootPath = history.location.pathname.match(/^\/[^/]*/)[0];
  return (
    <Tabs value={rootPath} onChange={handleTabChange}>
      <Tab value="/" label={t('tab_dashboard')} />
      <Tab value="/leagues" label={t('tab_leagues')} />
      <Tab value="/messages" label={t('tab_messages')} />
      <Tab value="/news" label={t('tab_news')} />
      <Tab value="/admin" label={t('tab_admin')} />
    </Tabs>
  )
}

const Header = () => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState(null);
  const { loading, logout, user } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  const handleMenuOpenClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  }

  const handleMenuItemClick = (path) => {
    handleMenuClose();
    history.push(path);
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  }

  const handleNotificationsOpenClick = (e) => {
    setNotificationsAnchorEl(e.currentTarget);
  }

  const handleNotificationsMenuClose = () => {
    setNotificationsAnchorEl(null);
  }

  const doLogout = () => {
    logout({})
  }

  if (loading || !user) return null;

  return (
    <React.Fragment>
      <AppBar position="sticky" color="inherit">
        <HeaderWrapper>
          <HeaderLeft>
            <Typography variant="h6">
              {t('app_name')}
            </Typography>
            <HeaderTabs />
          </HeaderLeft>
          <HeaderRight>
            <IconButton onClick={handleNotificationsOpenClick}>
              <Badge badgeContent={1} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={notificationsAnchorEl}
              open={!!notificationsAnchorEl}
              keepMounted
              onClose={handleNotificationsMenuClose}
            >
              <MenuItem>
                <div>
                  This is a mock Notification
                </div>
              </MenuItem>
            </Menu>
            {user.name}
            <IconButton onClick={handleMenuOpenClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={!!menuAnchorEl}
              keepMounted
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('/profile')}>{t('nav_menu_profile')}</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('/settings')}>{t('nav_menu_settings')}</MenuItem>
              <MenuItem onClick={doLogout}>{t('logout')}</MenuItem>
            </Menu>
          </HeaderRight>
        </HeaderWrapper>
      </AppBar>
    </React.Fragment>
  )
};

Header.propTypes = {};

export default Header;
