import React from 'react';
import PropTypes from 'prop-types';
import Joi from '@hapi/joi';
import { useFormContext } from 'react-form';

import Checkbox from '../../Checkbox';
import FormDialog from '../../FormDialog';
import TextField from '../../TextField';

const FieldsWithContext = () => {
  const { values } = useFormContext();
  return (
    <React.Fragment>
      <TextField
        label="Name"
        field="name"
        placeholder="Points-per-first-down"
        validator={Joi.string().min(1).required()}
      />
      <TextField
        label="Description"
        field="description"
        placeholder="Points to award when a first down is gained"
        validator={Joi.string().required()}
      />
      <Checkbox
        label="Coach Enabled"
        field="coach_enabled"
      />
      <TextField
        label="Coach Points"
        field="coach"
        placeholder="0.1"
        validator={Joi.number()}
        type="number"
        required={values.coach_enabled}
      />
      <Checkbox
        label="Team Enabled"
        field="team_enabled"
      />
      <TextField
        label="Team Points"
        field="team"
        placeholder="0"
        validator={Joi.number()}
        type="number"
        required={values.team_enabled}
      />
      <Checkbox
        label="Player Enabled"
        field="player_enabled"
      />
      <TextField
        label="Player Points"
        field="player"
        placeholder="1"
        validator={Joi.number()}
        type="number"
        required={values.player_enabled}
      />
    </React.Fragment>
  );
}

const CreateRuleDialog = (props) => {

  return (
    <FormDialog
      contentText="Add a Rule"
      cta="Create"
      {...props}
    >
      <FieldsWithContext />
    </FormDialog>
  );
};

CreateRuleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  error: PropTypes.any,
};

export default CreateRuleDialog;
