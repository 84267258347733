import React from 'react';

import Checkbox from '../Checkbox';
import FormDialog from '../FormDialog';

const ComponentTest = () => {

    return (
        <FormDialog
          open={true}
          onClose={() => {}}
          contentText="This is a test checkbox"
          defaultValues={{
            enabled: []
          }}
        >
          <Checkbox
            label="Player Enabled"
            field="enabled"
            value={1}
            isArray
          />
          <Checkbox
            label="Coach Enabled"
            field="enabled"
            value={2}
            isArray
          />
        </FormDialog>
    );
};

export default ComponentTest;
