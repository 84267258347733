import React from 'react';
import { Switch } from 'react-router-dom';

import { useAuth } from '../../../contexts/auth';

import Link from '../../Link';
import PrivateRoute from '../../PrivateRoute';
import ComponentTest from '../../ComponentTest';
import ProfessionalLeaguesList from '../ProfessionalLeaguesList';
import ProfessionalLeagueSeasonsList from '../ProfessionalLeagueSeasonsList';
import ProfessionalLeagueRulesList from '../ProfessionalLeagueRulesList';
import ProfessionalLeaguePositionsList from '../ProfessionalLeaguePositionsList';

const AdminDash = () => {
  return (
    <div>
      <Link to="/admin/professional_leagues">Professional Leagues</Link>
    </div>
  )
}

function AdminRouter() {

  const { isAuthenticated, loading } = useAuth();
  if (!isAuthenticated || loading) return null;
  return (
    <Switch>
      <PrivateRoute exact path="/admin" component={AdminDash}/>
      <PrivateRoute exact path="/admin/component_test" component={ComponentTest}/>
      <PrivateRoute exact path="/admin/professional_leagues" component={ProfessionalLeaguesList}/>
      <PrivateRoute exact path="/admin/professional_leagues/:professionalLeagueId/seasons" component={ProfessionalLeagueSeasonsList}/>
      <PrivateRoute exact path="/admin/professional_leagues/:professionalLeagueId/positions" component={ProfessionalLeaguePositionsList}/>
      <PrivateRoute exact path="/admin/professional_leagues/:professionalLeagueId/rules" component={ProfessionalLeagueRulesList}/>
    </Switch>
  );
}

export default AdminRouter;
