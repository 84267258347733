import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import Joi from '@hapi/joi';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import TextField from '../TextField';

const CreateLeagueDialog = (props) => {

  const createForm = useForm({
    onSubmit: props.onClose,
  });

  return (
    <Dialog open={props.open} disableBackdropClick fullWidth onClose={() => props.onClose()}>
      <createForm.Form>
        <DialogContent>
          <DialogContentText>Create your league!</DialogContentText>
          <TextField label="Name" field="name" validator={Joi.string().min(1).required()} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!createForm.meta.canSubmit}
          >
            Create
          </Button>
        </DialogActions>
      </createForm.Form>
    </Dialog>
  )
};

CreateLeagueDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CreateLeagueDialog;
