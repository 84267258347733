import React from 'react';
import PropTypes from 'prop-types';
import Hoek from '@hapi/hoek';
import { useField } from 'react-form';
import { TextField as MuiTextField } from '@material-ui/core';

const TextField = ({
  validator,
  field,
  label,
  placeholder,
  defaultValue,
  validateDebounce,
  caption,
  autoFocus,
  type,
  required,
  disabled,
}) => {

    const isRequired = required || Hoek.reach(validator, '_flags.presence') === 'required';
    const {
        meta: { error, isTouched },
        getInputProps
    } = useField(field, {
        defaultValue,
        validatePristine: true,
        validate: (value, instance) => {
            if (!value && isRequired) return `This field is required`;
            if (!value && !isRequired) return true;
            return instance.debounce(() => {
                const result = validator.validate(value);
                return Hoek.reach(result, 'error.message', { default: false });
            }, validateDebounce);
        },
    });

    if (isTouched && error) {
        caption = error;
    }

    return (
        <MuiTextField
            label={label}
            placeholder={placeholder}
            error={isTouched && !!error}
            {...getInputProps()}
            helperText={isTouched && error ? error : caption}
            autoFocus={autoFocus}
            type={type}
            disabled={disabled}
            fullWidth
            required={isRequired}
            margin="normal"
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
        />
    );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  validator: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  defaultValue: PropTypes.string,
  validateDebounce: PropTypes.number,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

TextField.defaultProps = {
  placeholder: '',
  caption: '',
  defaultValue: '',
  validateDebounce: 200,
  autoFocus: false,
  required: false,
  disabled: false,
  type: 'text',
};

export default TextField;
