// https://auth0.com/docs/quickstart/spa/react/01-login

import React from 'react';
import Immutable from 'immutable';

const ACTIONS = {
  REFRESH_STARTED: 'refresh_started',
  REFRESH_SUCCESS: 'refresh_success',
  REFRESH_ERROR: 'refresh_error',
  CREATE_SUCCESS: 'create_success',
  CREATE_STARTED: 'create_started',
  CREATE_ERROR: 'create_error',
  PUT_SUCCESS: 'put_success',
  PUT_STARTED: 'put_started',
  PUT_ERROR: 'put_error',
};

const INITIAL_STATE = Immutable.fromJS({
  leaguesById: {},
  loading: true,
  updating: false,
  creating: false,
  error: false,
});

const DataContext = React.createContext(null);
const DispatchContext = React.createContext(null);

const leaguesReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.REFRESH_STARTED: {
      return state.set('loading', true);
    }
    case ACTIONS.REFRESH_SUCCESS: {
      const byId = {};
      action.leagues.forEach(l => byId[l.id] = l);
      return state
        .mergeIn(['leaguesById'], byId)
        .set('error', false)
        .set('loading', false);
    }
    case ACTIONS.REFRESH_ERROR: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case ACTIONS.CREATE_STARTED: {
      return state.set('creating', true);
    }
    case ACTIONS.CREATE_SUCCESS: {
      return state
        .set('creating', false)
        .set('error', false)
        .setIn(['leaguesById', action.league.id], action.league);
    }
    case ACTIONS.CREATE_ERROR: {
      return state
        .set('creating', false)
        .set('error', action.error);
    }
    case ACTIONS.PUT_STARTED: {
      return state.set('updating', true);
    }
    case ACTIONS.PUT_SUCCESS: {
      return state
        .set('updating', false)
        .set('error', false)
        .setIn(['leaguesById', action.league.id], action.league);
    }
    case ACTIONS.PUT_ERROR: {
      return state
        .set('updating', false)
        .set('error', action.error);
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
};

export const refreshProfessionalLeagues = async (dispatch, fetchAuth) => {
  dispatch({ type: ACTIONS.REFRESH_STARTED });
  const leagues = await fetchAuth('/api/v1/professional_leagues');
  dispatch({
    type: ACTIONS.REFRESH_SUCCESS,
    leagues,
  });
};

export const createProfessionalLeague = async (dispatch, fetchAuth, attrs) => {
  dispatch({ type: ACTIONS.CREATE_STARTED });
  try {
    const league = await fetchAuth('/api/v1/professional_leagues', {
      method: 'POST',
      body: attrs,
    });
    dispatch({
      type: ACTIONS.CREATE_SUCCESS,
      league,
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.CREATE_ERROR,
      error: e,
    });
    throw e;
  }
};

export const putProfessionalLeague = async (dispatch, fetchAuth, attrs) => {
  dispatch({ type: ACTIONS.PUT_STARTED });
  try {
    const league = await fetchAuth(`/api/v1/professional_leagues/${attrs.id}`, {
      method: 'PUT',
      body: attrs,
    });
    dispatch({
      type: ACTIONS.PUT_SUCCESS,
      league,
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.PUT_ERROR,
      error: e,
    });
    throw e;
  }
};

export const ProfessionalLeaguesProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(leaguesReducer, INITIAL_STATE);
  return (
    <DataContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </DataContext.Provider>
  )
};

export const useProfessionalLeagues = () => {
  const dataC = React.useContext(DataContext);
  const dispC = React.useContext(DispatchContext);
  if (!dataC || !dispC) throw new Error('useProfessionalLeagues must be used within a ProfessionalLeaguesProvider');

  return [dataC.toJS(), dispC];
};
