// https://auth0.com/docs/quickstart/spa/react/01-login

import React from 'react';
import Immutable from 'immutable';

const ACTIONS = {
  REFRESH_STARTED: 'refresh_started',
  REFRESH_SUCCESS: 'refresh_success',
  REFRESH_ERROR: 'refresh_error',
  CREATE_SUCCESS: 'create_success',
  CREATE_STARTED: 'create_started',
  CREATE_ERROR: 'create_error',
  PUT_SUCCESS: 'put_success',
  PUT_STARTED: 'put_started',
  PUT_ERROR: 'put_error',
};

const INITIAL_STATE = Immutable.fromJS({
  rulesByProfessionalLeagueId: [],
  loading: true,
  updating: false,
  creating: false,
  error: false,
});

const DataContext = React.createContext(null);
const DispatchContext = React.createContext(null);

const rulesReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.REFRESH_STARTED: {
      return state.set('loading', true);
    }
    case ACTIONS.REFRESH_SUCCESS: {
      const byId = {};
      action.rules.forEach(s => byId[s.id] = s);
      return state
        .setIn(['rulesByProfessionalLeagueId', action.professionalLeagueId], byId)
        .set('error', false)
        .set('loading', false);
    }
    case ACTIONS.REFRESH_ERROR: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    case ACTIONS.CREATE_STARTED: {
      return state.set('creating', true);
    }
    case ACTIONS.CREATE_SUCCESS: {
      return state
        .set('creating', false)
        .set('error', false)
        .setIn(['rulesByProfessionalLeagueId', action.professionalLeagueId, action.rule.id], action.rule);
    }
    case ACTIONS.CREATE_ERROR: {
      return state
        .set('creating', false)
        .set('error', action.error);
    }
    case ACTIONS.PUT_STARTED: {
      return state.set('updating', true);
    }
    case ACTIONS.PUT_SUCCESS: {
      return state
        .set('updating', false)
        .set('error', false)
        .setIn(['rulesByProfessionalLeagueId', action.professionalLeagueId, action.rule.id], action.rule);
    }
    case ACTIONS.PUT_ERROR: {
      return state
        .set('updating', false)
        .set('error', action.error);
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
};

export const refreshRules = async (dispatch, fetchAuth, professionalLeagueId) => {
  dispatch({ type: ACTIONS.REFRESH_STARTED, professionalLeagueId });
  const rules = await fetchAuth(`/api/v1/professional_leagues/${professionalLeagueId}/rules`);
  dispatch({
    type: ACTIONS.REFRESH_SUCCESS,
    rules,
    professionalLeagueId,
  });
}

export const createRule = async (dispatch, fetchAuth, professionalLeagueId, attrs) => {
  dispatch({ type: ACTIONS.CREATE_STARTED, professionalLeagueId });
  const body = Object.assign({}, attrs);
  if (body.hasOwnProperty('team') && !body.team) delete body.team;
  if (body.hasOwnProperty('player') && !body.player) delete body.player;
  if (body.hasOwnProperty('coach') && !body.coach) delete body.coach;
  try {
    const rule = await fetchAuth(`/api/v1/professional_leagues/${professionalLeagueId}/rules`, {
      method: 'POST',
      body,
    });
    dispatch({
      type: ACTIONS.CREATE_SUCCESS,
      rule,
      professionalLeagueId,
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.CREATE_ERROR,
      error: e,
      professionalLeagueId,
    });
    throw e;
  }
}

export const putRule = async (dispatch, fetchAuth, professionalLeagueId, attrs) => {
  dispatch({ type: ACTIONS.PUT_STARTED, professionalLeagueId });
  try {
    const rule = await fetchAuth(`/api/v1/professional_leagues/${professionalLeagueId}/rules/${attrs.id}`, {
      method: 'PUT',
      body: attrs,
    });
    dispatch({
      type: ACTIONS.PUT_SUCCESS,
      rule,
      professionalLeagueId,
    });
  } catch (e) {
    dispatch({
      type: ACTIONS.PUT_ERROR,
      error: e,
      professionalLeagueId,
    });
    throw e;
  }
}

export const RulesProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(rulesReducer, INITIAL_STATE);
  return (
    <DataContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </DataContext.Provider>
  )
}

export const useRules = () => {
  const dataC = React.useContext(DataContext);
  const dispC = React.useContext(DispatchContext);
  if (!dataC || !dispC) throw new Error('useRules must be used within a RulesProvider');

  return [dataC.toJS(), dispC];
}
