import React from 'react';
import PropTypes from 'prop-types';
import Joi from '@hapi/joi';

import FormDialog from '../../FormDialog';
import TextField from '../../TextField';

const SeasonDialog = (props) => {

  return (
    <FormDialog
      {...props}
    >
      <TextField
        label="Season Name"
        field="name"
        placeholder="2019-20"
        validator={Joi.string().min(1).required()}
      />
      <TextField
        label="Starting Year"
        field="year"
        placeholder="2019"
        validator={Joi.number().integer().min(2000).required()}
      />
    </FormDialog>
  );
};

SeasonDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  cta: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  error: PropTypes.any,
};

export default SeasonDialog;
