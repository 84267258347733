import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import Alert from '../Alert';

const FormDialog = (props) => {

  const createForm = useForm({
    onSubmit: props.onClose,
    defaultValues: props.defaultValues,
  });

  return (
    <Dialog open={props.open} disableBackdropClick fullWidth onClose={() => props.onClose()}>
      <createForm.Form>
        <DialogContent>
          <DialogContentText>{props.contentText}</DialogContentText>
          {props.children}
          <Alert error={props.error} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!createForm.meta.canSubmit}
          >
            {props.cta}
          </Button>
        </DialogActions>
      </createForm.Form>
    </Dialog>
  )
};

FormDialog.propTypes = {
  contentText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  error: PropTypes.any,
  cta: PropTypes.string,
};

FormDialog.defaultProps = {
  cta: 'Create',
  defaultValues: {},
};

export default FormDialog;
