import React from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper,
} from './styles';

const convertErrorToString = (error) => {
  if (error instanceof Error) return error.message;
  if (typeof error === 'string') return error;
  return 'An unknown error has occurred.';
}

const Alert = (props) => {
  if (!props.error) return null;
  return (
    <Wrapper>
      {convertErrorToString(props.error)}
    </Wrapper>
  )
};

Alert.propTypes = {
  error: PropTypes.any,
};

export default Alert;
